* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body {
  background-image: url("../public/geometric_background.png");
  background-size: 100vw 100vh;
}

