.App {
  text-align: center;
  background-color: #115E38;
  width: 100vw;
  height: 100vh;
}



.Preview__container__document {
  margin: 1em 0;
  width: 100%;
  padding: initial;
}

.Preview__container__document .react-pdf__Document {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
}

.Preview__container__document .react-pdf__Page {
  display: block;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: calc(100% - 2em);
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  /*margin: 1em;*/
  height: 100%;
  width: fit-content;
  max-height: 500px;
  padding: 10px;
}

.Preview__container__document .react-pdf__Page canvas {
  width: auto !important;
  margin: auto;
  height: 100% !important;

}

.Preview__container__document .react-pdf__Page__annotations{
  width: 0px !important;
  height: 0px !important;
}

/*.react-pdf__Document {*/
/*    display: block;*/
/*    margin: 0 auto ;*/
/*    width: 50% !important;*/
/*    height: 50% !important;*/
/*    border: 1px solid green*/
/*}*/

/*.react-pdf__Page {*/
/*    position: center !important;*/
/*    margin: 0 auto ;*/
/*    width: 50% !important;*/
/*    height: 50% !important;*/
/*    border: 1px solid green*/
/*}*/

/*.react-pdf__Page__canvas {*/
/*    margin: 0 auto ;*/
/*    width: 50% !important;*/
/*    height: 50% !important;*/
/*    border: 1px solid green*/
/*}*/


        /*display: 'block',*/
        /*alignItems:'center',*/
        /*width: '100%',*/
        /*height: '100%',*/
        /*maxHeight: '500px',*/
        /*backgroundColor: '$lightgray',*/
        /*textAlign:'left',*/
        /*borderRadius: '15px',*/
        /*padding: '15px',*/
        /*marginY: '15px',*/
        /*overflow: 'scroll',*/